import React, { useEffect } from "react"
import useTwilioVideo from "../hooks/use-twilio-video"
import { navigate, StaticQuery, useStaticQuery } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import styled from "styled-components"

const APOLLO_QUERY = gql`
  query allCompletedProposals {
    allCompletedProposals {
      Address
    }
  }
`

const MainCon = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: 600px;
  /* border: solid 1px; */
  grid-gap: 20px;
  grid-auto-rows: auto;
  grid-template-rows: 1fr;

  .col-1 {
  }

  .chat {
  }

  video {
    display: block;
    width: 100%;
    /* border: solid 2px lightskyblue; */
    border-radius: 5px;
  }

  .col-1,
  .col-2 {
    /* border: solid 2px lightskyblue; */
    border-radius: 5px;
  }

  .content {
  }
`

const VideoDisplay = ({ roomID }) => {
  const { data, error, loading } = useQuery(APOLLO_QUERY)
  console.log(data, error, loading)
  const {
    state,
    startVideo,
    videoRef,
    screenShareRef,
    leaveRoom,
    shareScreen,
  } = useTwilioVideo()
  useEffect(() => {
    if (!state.token) {
      navigate("/", { state: { roomName: roomID } })
    }

    if (!state.room) {
      startVideo()
    }
    window.addEventListener("beforeunload", leaveRoom)
    return () => {
      window.removeEventListener("beforeunload", leaveRoom)
    }
  }, [state, roomID, startVideo, leaveRoom])
  console.log(state)
  return (
    <>
      {state.room && (
        <div className="buttons">
          <button className="leave-room" onClick={shareScreen}>
            Share
          </button>
        </div>
      )}
      <MainCon>
        <div ref={videoRef} className="chat col-1"></div>
        <div ref={screenShareRef} className="col-2 content"></div>
      </MainCon>

      {/* <div className="chat" ref={videoRef}></div>
      <div className="presentation" ref={screenShareRef}></div> */}
    </>
  )
}

// export const query = graphql`
//   query CompletedQuery {
//     swapi {
//       allCompletedProposals {
//         Address
//       }
//     }
//   }
// `

export default VideoDisplay
